<template>
  <!-- Mobile sidebar -->
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog
      as="div"
      class="fixed inset-0 flex z-40 lg:hidden"
      @close="toggleSidebar(false)"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full"
      >
        <div
          class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white dark:bg-gray-800"
        >
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button
                type="button"
                class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:border-none close-btn text-main4"
                @click="toggleSidebar(false)"
              >
                <span class="sr-only">Close sidebar</span>
                <XIcon />
              </button>
            </div>
          </TransitionChild>
          <div class="flex-shrink-0 flex items-center px-4">
            <img
              class="h-10 w-auto block dark:hidden"
              src="../../assets/logo.svg"
              alt="BTE"
            />
            <img
              class="h-10 w-auto hidden dark:block"
              src="../../assets/logo-light.svg"
              alt="BTE"
            />
            <p class="text-main1 font-semibold text-base -mt-1 dark:text-white">DUO Lite</p>
          </div>
          <div class="mt-5 flex-1 h-0 overflow-y-auto">
            <!-- Mobile navigation -->
            <nav class="px-2 space-y-1">
              <template v-for="(link, index) in links" :key="index">
                <router-link
                  v-if="link.route && checkIfLinkIsAllowed(link.permission)"
                  :to="link.route"
                  :class="[
                    getActiveLink(index, link.route, link)
                      ? 'bg-main4 text-white'
                      : 'dark:text-gray-300',
                    'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                  ]"
                >
                  <component
                    :is="getIcon(link.icon)"
                    class="w-5 h-5"
                    :class="
                      getActiveLink(index, link.route, link) ? 'text-white' : ''
                    "
                  />
                  <span class="ml-3">
                    {{ link.text }}
                  </span>
                </router-link>

                <div
                  v-if="
                    link.children && user.permissions.includes(link.permission)
                  "
                >
                  <span
                    :class="[
                      getActiveLink(index)
                        ? 'bg, link-main4 text-white'
                        : 'dark:text-gray-300',
                      'group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer',
                    ]"
                    @click="openMenu(index)"
                  >
                    <component
                      :is="getIcon(link.icon)"
                      class="w-5 h-5"
                      :class="
                        getActiveLink(index, link.route, link)
                          ? 'text-white'
                          : ''
                      "
                    />
                    <span class="ml-3">
                      {{ link.text }}
                    </span>
                  </span>
                  <div
                    :class="[
                      index == activeMenuDropdown || index == activePageDropdown
                        ? 'max-h-screen'
                        : 'max-h-0',
                      'pl-3  overflow-hidden',
                    ]"
                  >
                    <template v-for="(child, i) in link.children" :key="i">
                      <template
                        v-if="user.permissions.includes(child.permission)"
                      >
                        <router-link
                          :to="child.route"
                          :class="[
                            getActiveLink(index, child.route, child, i)
                              ? 'bg-main4 text-white'
                              : 'dark:text-gray-300',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                          ]"
                        >
                          <component
                            :is="getIcon('ChevronRight')"
                            class="w-4 h-4"
                            :class="
                              getActiveLink(index, link.route, link)
                                ? 'text-white'
                                : ''
                            "
                          />
                          {{ child.text }}
                        </router-link>
                      </template>
                    </template>
                  </div>
                </div>
              </template>
            </nav>
          </div>

          <div class="flex items-center justify-between pt-4">
            <div class="flex items-center pl-2">
              <Switch v-model="darkMode" color="bg-gray-400" />
              <p class="text-xs pl-2 dark:text-gray-300">
                <span class="dark:hidden"> Dark mode </span>
                <span class="hidden dark:block"> Light mode </span>
              </p>
            </div>

            <button
              class="logout flex align-center text-base leading-3 justify-end mr-3 dark:text-gray-300
              "
              @click="logout"
            >
              <LogoutIcon class="w-4 h-4" />
              <span class="ml-1">Iziet</span>
            </button>
          </div>
        </div>
      </TransitionChild>
      <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Static sidebar for desktop -->
  <div class="hidden lg:flex lg:flex-shrink-0 z-10">
    <div class="flex flex-col w-64">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex flex-col flex-grow border-r border-gray-200 dark:border-gray-700 dark:border-gray-700 pt-5 pb-4 bg-white dark:bg-gray-800 overflow-y-auto"
      >
        <div class="flex items-center flex-shrink-0 px-4">
          <router-link to="/" class="flex items-center">
            <img
              class="h-12 w-auto block dark:hidden"
              src="../../assets/logo.svg"
              alt="BTE"
            />
            <img
              class="h-12 w-auto hidden dark:block"
              src="../../assets/logo-light.svg"
              alt="BTE"
            />
            <p class="text-main1 dark:text-white font-semibold -mt-1">DUO Lite</p>
          </router-link>
        </div>
        <div class="mt-5 flex-grow flex flex-col">
          <!-- Desktop navigation -->
          <nav class="flex-1 px-2 space-y-1">
            <template v-for="(link, index) in links" :key="index">
              <router-link
                v-if="link.route && checkIfLinkIsAllowed(link.permission)"
                :to="link.route"
                :class="[
                  getActiveLink(index, link.route, link)
                    ? 'bg-main4 text-white'
                    : 'dark:text-gray-300',
                  '  group flex items-center px-2 py-2 text-base font-medium rounded-md',
                ]"
              >
                <component
                  :is="getIcon(link.icon)"
                  class="w-5 h-5"
                  :class="
                    getActiveLink(index, link.route, link) ? 'text-white' : ''
                  "
                />
                <span class="ml-3 flex w-full">
                  {{ link.text }}

                  <template v-if="link.create_link">
                    <div class="ml-auto">
                      <router-link :to="link.create_link">
                        <span class="block rounded-full">
                          <PlusIcon class="w-5 h-5" />
                        </span>
                      </router-link>
                    </div>
                  </template>
                </span>
              </router-link>

              <div
                v-if="
                  link.children && user.permissions.includes(link.permission)
                "
              >
                <span
                  :class="[
                    getActiveLink(index)
                      ? 'bg, link-main4 text-white'
                      : 'dark:text-gray-300',
                    '  group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer',
                  ]"
                  @click="openMenu(index)"
                >
                  <component
                    :is="getIcon(link.icon)"
                    class="w-5 h-5"
                    :class="
                      getActiveLink(index, link.route, link) ? 'text-white' : ''
                    "
                  />
                  <span class="ml-3">
                    {{ link.text }}
                  </span>
                </span>
                <div
                  :class="[
                    index == activeMenuDropdown || index == activePageDropdown
                      ? 'max-h-screen'
                      : 'max-h-0',
                    'pl-3  overflow-hidden',
                  ]"
                >
                  <template v-for="(child, i) in link.children" :key="i">
                    <template
                      v-if="user.permissions.includes(child.permission)"
                    >
                      <router-link
                        :to="child.route"
                        :class="[
                          getActiveLink(index, child.route, child, i)
                            ? 'bg-main4 text-white'
                            : 'dark:text-gray-300',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                        ]"
                      >
                        <component
                          :is="getIcon('ChevronRight')"
                          class="w-4 h-4"
                          :class="
                            getActiveLink(index, link.route, link)
                              ? 'text-white'
                              : 'dark:text-gray-300'
                          "
                        />
                        {{ child.text }}

                        <template v-if="child.create_link">
                          <div class="ml-auto">
                            <router-link :to="child.create_link">
                              <span class="block rounded-full">
                                <PlusIcon class="w-5 h-5" />
                              </span>
                            </router-link>
                          </div>
                        </template>
                      </router-link>
                    </template>
                  </template>
                </div>
              </div>
            </template>
          </nav>
        </div>

        <div class="flex items-center justify-between pt-4">
          <div class="flex items-center pl-2">
            <Switch v-model="darkMode" color="bg-gray-400" />
            <p class="text-xs pl-2 dark:text-gray-300">
              <span class="dark:hidden"> Dark mode </span>
              <span class="hidden dark:block"> Light mode </span>
            </p>
          </div>

          <button
            class="
              logout
              flex
              align-center
              text-base
              leading-3
              justify-end
              mr-3
              dark:text-gray-300
            "
            @click="logout"
          >
            <LogoutIcon class="w-4 h-4" />
            <span class="ml-1">Iziet</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import links from "../../library/navigation/links";
import { mapGetters } from "vuex";
import {
  HomeIcon,
  UserGroupIcon,
  SwitchHorizontalIcon,
  ClipboardCheckIcon,
  OfficeBuildingIcon,
  DocumentTextIcon,
  ColorSwatchIcon,
  TableIcon,
  TruckIcon,
  BookOpenIcon,
  CogIcon,
  ChevronRightIcon,
  ClipboardListIcon,
  XIcon,
  LogoutIcon,
  PlusIcon,
} from "@heroicons/vue/outline";

import Switch from "@/components/Components/Switch";

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    HomeIcon,
    UserGroupIcon,
    SwitchHorizontalIcon,
    ClipboardCheckIcon,
    OfficeBuildingIcon,
    DocumentTextIcon,
    ColorSwatchIcon,
    TableIcon,
    TruckIcon,
    BookOpenIcon,
    CogIcon,
    ChevronRightIcon,
    ClipboardListIcon,
    XIcon,
    LogoutIcon,
    Switch,
    PlusIcon,
  },
  data() {
    return {
      links: links,
      activePageDropdown: null,
      activeMenuDropdown: null,
      darkMode: false,
    };
  },
  created() {
    this.darkMode = localStorage.getItem("darkMode") === "true" ? true : false;

    this.$store.commit('setDarkModeStatus', this.darkMode)
  },
  watch: {
    darkMode() {
      localStorage.darkMode = this.darkMode;
      this.$store.commit('setDarkModeStatus', this.darkMode)

      if (this.darkMode) {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    },
  },
  emits: ["darkMode"],
  computed: {
    ...mapGetters({
      currentPage: "currentPage",
      user: "user",
      sidebarOpen: "sidebarOpen",
    }),
  },
  methods: {
    toggleSidebar(value) {
      this.$store.dispatch("setSidebarOpen", value);
    },
    openMenu(index) {
      this.activeMenuDropdown;
      this.activeMenuDropdown == index
        ? (this.activeMenuDropdown = null)
        : (this.activeMenuDropdown = index);
    },
    getActiveLink(index, route, link, childIndex) {
      const additional =
        link && link.additionalLinks ? link.additionalLinks : [];
      let currentPage = this.currentPage;

      if (this.$route.params) {
        const params = Object.values(this.$route.params);
        params.forEach((param) => {
          currentPage = currentPage.replace(`/${param}`, "");
        });
      }

      if (route == currentPage || additional.includes(currentPage)) {
        childIndex || childIndex === 0
          ? (this.activePageDropdown = index)
          : (this.activePageDropdown = null);
        return true;
      }
    },
    logout() {
      this.$store.dispatch("destroyToken").then(() => {
        setTimeout(() => {
          this.$router.push("/login");
        }, 300);
      });
    },
    checkIfLinkIsAllowed(linkPermission) {
      if (linkPermission === "") {
        return true;
      }
      return this.user.permissions.includes(linkPermission);
    },
    getIcon(name) {
      const icons = {
        Home: "HomeIcon",
        UserGroup: "UserGroupIcon",
        SwitchHorizontal: "SwitchHorizontalIcon",
        ClipboardCheck: "ClipboardCheckIcon",
        DocumentText: "DocumentTextIcon",
        OfficeBuilding: "OfficeBuildingIcon",
        ColorSwatch: "ColorSwatchIcon",
        Table: "TableIcon",
        Truck: "TruckIcon",
        BookOpen: "BookOpenIcon",
        Cog: "CogIcon",
        ChevronRight: "ChevronRightIcon",
        ClipboardList: "ClipboardListIcon",
      };

      return icons[name];
    },
  },
};
</script>
