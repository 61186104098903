
const links = [

    { icon: 'Home', text: 'Sākums', route: '/', permission: ''},
    { icon: 'UserGroup', text: 'Klienti', route: '/customers', permission: 'view_customers', additionalLinks: ["/customers/orders", "/customers/prices"]},
    { icon: 'ClipboardCheck', text: 'Pasūtījumi', route: '/orders', permission: 'view_orders', create_link: '/orders/create'},
    { icon: 'DocumentText', text: 'Rēķini', route: '/invoices', permission: 'view_invoices', additionalLinks: ['/invoices/invoiceable_customers', '/invoices/export', '/invoices/invoice', '/invoices/reports', '/invoices/sonax']},
    {
        icon: 'OfficeBuilding',
        iconAlt: 'mdi-database',
        text: 'Noliktava',
        model: false,
        permission: 'view_stock',
        children: [
            { icon: 'ChevronRight', text: 'Atlikumi', route: '/stock/stock_items', permission: 'view_stock' },
            { icon: 'ChevronRight', text: 'Iepirkumi', route: '/stock/procurements', permission: 'view_procurement', create_link: '/stock/procurements/create' },
            { icon: 'ChevronRight', text: 'Iepirkumu atgriešana', route: '/stock/procurement_return_acts', permission: 'view_procurement' },
            { icon: 'ChevronRight', text: 'Iekārtas / Inventārs', route: '/stock/inventory_items', permission: 'view_inventory_items', create_link: '/stock/inventory_items/create' },
            { icon: 'ChevronRight', text: 'Noliktavas koriģēšana', route: '/stock/tehnical_documents', permission: 'manage_technical_documents', create_link: '/stock/tehnical_documents/create' },
            { icon: 'ChevronRight', text: 'Piegādātāji', route: '/stock/suppliers', permission: 'view_suppliers', additionalLinks: ["/stock/suppliers/categories"] },
            { icon: 'ChevronRight', text: 'Inventerizācija', route: '/stock/stock_checks', permission: 'manage_xml', create_link: "/stock/stock_checks/create" },
            { icon: 'ChevronRight', text: 'Atskaites', route: '/stock/reports', permission: 'view_stock' },
        ],
    },

    { icon: 'BookOpen', text: 'Katalogs', route: '/catalog', permission: 'view_catalog_items' },
    { icon: 'BookOpen', text: 'Kategorijas', route: '/categories', permission: 'manage_categories' },
    { icon: 'ClipboardList', text: 'Vienības', route: '/catalog_item_units', permission: 'view_catalog_items' },
    {
        icon: 'Cog',
        iconAlt: 'mdi-cog-outline',
        text: 'Vadības panelis',
        model: false,
        permission: 'view_settings',
        children: [
            { icon: 'ChevronRight', text: 'Lietotāji', route: '/users', permission: 'manage_users' },
            { icon: 'ChevronRight', text: 'Lomas', route: '/roles', permission: 'manage_roles' },
            { icon: 'ChevronRight', text: 'Parametri', route: '/params', permission: 'manage_catalog_params' },
            { icon: 'ChevronRight', text: 'Bankas', route: '/banks', permission: 'manage_banks' },
            { icon: 'ChevronRight', text: 'Uzņēmumu veidi', route: '/company_forms', permission: 'manage_company_forms' },
            { icon: 'ChevronRight', text: 'Noliktavas', route: '/warehouses', permission: 'manage_warehouses' },
            { icon: 'ChevronRight', text: 'Mērvienības', route: '/measure_units', permission: 'manage_measure_units' },
            { icon: 'ChevronRight', text: 'Svētki', route: '/celebrations', permission: 'manage_roles' },
        ],
    },
]

export default links
