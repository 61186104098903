<template>
  <div class="h-screen flex overflow-hidden bg-gray-100 dark:bg-gray-750">
    <!-- Sidebar -->
    <Sidebar />

    <router-view :key="$route.fullPath"></router-view>

  </div>
</template>

<script>
import Sidebar from "@/components/Base/Sidebar"

export default {
  components: {
    Sidebar
  },
}
</script>
